import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Routes from "../routes"
import { useImpression } from "../analytics/utils"

const Sitemap = ({ location, menuLinks }) => {
  useImpression("Sitemap")
  const data = useStaticQuery(graphql`
    query MenuLinks {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <Seo
        title="Site map"
        description="See all the places you can visit to learn about what religion is, what forms it has, its criticisms and debates."
      />
      <h1>Site map</h1>

      <ul>
        {[
          ...data.site.siteMetadata.menuLinks,
          { name: "Terms and conditions", link: Routes.TERMS },
          { name: "Privacy", link: Routes.PRIVACY },
        ].map(({ name, link }) => {
          return (
            <div key={name}>
              <li key={name}>
                <Link to={link}>{name}</Link>
              </li>
            </div>
          )
        })}
      </ul>
    </Layout>
  )
}
export default Sitemap
